import React, { useState } from "react"

export default function FloatingSearch({ clear }) {
  const [searchVal, setSearchVal] = useState()
  return (
    <div className="g-floating-search">
      <form
        onSubmit={e => {
          e.preventDefault()
          window.location.replace(`/search/?query=${searchVal}`)
        }}
        autoComplete="off"
      >
        <label className={`search ${clear ? "clear" : ""}`}>
          <input
            type="text"
            value={searchVal}
            onChange={e => {
              setSearchVal(e.target.value)
            }}
          />
        </label>
      </form>
    </div>
  )
}
