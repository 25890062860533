import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import ColoredHeader from "../../components/coloredHeader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons"
import FloatingSearch from "../../components/floatingSearch"

import atlas_lg_blackwhite from "../../../static/background_images/atlas_lg_blackwhite.jpg"

export default function History(props) {
  const [reachedHeight, setReachedHeight] = useState(-32)
  const [individualReaches, setIndividualReaches] = useState({})
  const refGrowBar = useRef()
  const ref2022 = useRef()
  const ref2023 = useRef()
  const ref2021 = useRef()
  const ref2020 = useRef()
  const ref2019 = useRef()
  const ref2018 = useRef()
  const ref2017 = useRef()
  const ref2016 = useRef()
  const ref2015 = useRef()
  const ref2014 = useRef()
  const ref2013 = useRef()
  const ref2012 = useRef()
  const ref2011 = useRef()
  const ref2010 = useRef()
  const ref2009 = useRef()
  const ref2008 = useRef()
  const ref2007 = useRef()
  const ref2006 = useRef()
  const ref2005 = useRef()
  const ref2004 = useRef()
  const ref2003 = useRef()
  const ref2002 = useRef()
  const ref2001 = useRef()
  const ref2000 = useRef()
  const ref1999 = useRef()

  const calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset
    const refGrowBarspaceAbove =
      refGrowBar.current && refGrowBar.current.getBoundingClientRect().top
    const spaceDiff = refGrowBarspaceAbove + scrollTop
    const hr2023 = ref2023.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2022 = ref2022.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2021 = ref2021.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2020 = ref2020.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2019 = ref2019.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2018 = ref2018.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2017 = ref2017.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2016 = ref2016.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2015 = ref2015.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2014 = ref2014.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2013 = ref2013.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2012 = ref2012.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2011 = ref2011.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2010 = ref2010.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2009 = ref2009.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2008 = ref2008.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2007 = ref2007.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2006 = ref2006.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2005 = ref2005.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2004 = ref2004.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2003 = ref2003.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2002 = ref2002.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2001 = ref2001.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr2000 = ref2000.current.getBoundingClientRect().y - spaceDiff <= 0
    const hr1999 = ref1999.current.getBoundingClientRect().y - spaceDiff <= 0
    setIndividualReaches({
      hr2023,
      hr2022,
      hr2021,
      hr2020,
      hr2019,
      hr2018,
      hr2017,
      hr2016,
      hr2015,
      hr2014,
      hr2013,
      hr2012,
      hr2011,
      hr2010,
      hr2009,
      hr2008,
      hr2007,
      hr2006,
      hr2005,
      hr2004,
      hr2003,
      hr2002,
      hr2001,
      hr2000,
      hr1999,
    })

    setReachedHeight(scrollTop)
  }

  useEffect(() => {
    document.addEventListener("scroll", () => {
      requestAnimationFrame(() => {
        // Calculates the scroll distance
        calculateScrollDistance()
      })
    })
  }, [])
  return (
    <Layout>
      <Helmet>
        <title>History – EOG Resources, Inc.</title>
      </Helmet>
      <ColoredHeader
        title="COMPANY"
        header="High Return Organic Growth"
        overlayColor1="#d50000"
        overlayColor2="#da3433"
        lineColor="#f95c4f"
        bgImageSRC={atlas_lg_blackwhite}
      />
      <div className="max-width-container absolute desktop-only">
        <FloatingSearch clear />
      </div>
      <div className="page history-page last-page">
        <VerticalTimeline>
          <VerticalTimelineElement
            date="2023"
            className="first-elem"
            iconStyle={{
              background: `${individualReaches["hr2023"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2022}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                2023 was a year of record production and outstanding financial
                performance. We delivered on our volume targets and reached a
                production milestone, exiting the year producing more than 1
                million barrels of oil equivalent per day. We earned adjusted
                net income of $6.8 billion<sup>*</sup> for a return on capital
                employed of 28%.<sup>*</sup>
              </p>
              <p>
                <strong>
                  We generated $5.1 billion<sup>*</sup> of free cash flow and
                  returned more than 85% of that free cash flow to shareholders
                  through $3.30 per share of regular dividends, $2.50 per share
                  of special dividends, and $1.0 billion of share repurchases.
                  Our regular dividend remains the anchor of our cash return
                  strategy. We increased it 10% last year to an annualized rate
                  of $3.64 per share.
                </strong>
              </p>
              <p>
                EOG maintained our GHG and methane emissions rates below 2025
                targets, based on preliminary 2023 estimates, and achieved zero
                routine flaring ahead of EOG's 2025 target and the World Bank's
                2030 initiative.
              </p>
              <p>
                <sup>*</sup> See reconciliation schedules.
              </p>
            </div>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            date="2022"
            className=""
            iconStyle={{
              background: `${individualReaches["hr2022"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2022}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                2022 was an outstanding year for EOG. The company earned record
                return on capital employed of 34%<sup>*</sup> and record
                adjusted net income of $8.1 billion<sup>*</sup>, which generated
                a record $7.6 billion of free cash flow<sup>*</sup>. Over $5
                billion was returned to shareholders, nearly double the 2021
                return.
              </p>
              <p>
                <strong>
                  EOG increased the regular dividend rate by 10% and paid four
                  special dividends equating to over 65% of free cash flow
                  <sup>*</sup>, beating the company's minimum commitment to
                  return 60% of annual free cash flow to shareholders.
                </strong>
              </p>
              <p>
                By leveraging the company's decentralized structure, EOG offset
                persistent inflationary pressure to limit well cost increases to
                just 7%. Exploration teams uncovered a new premium play, the
                Ohio Utica Combo, and advanced two emerging plays, the South
                Texas Dorado and Southern Powder River Basin, while progressing
                several exploration prospects.
              </p>
              <p>
                EOG also reduced GHG intensity and methane emissions percentage
                and increased wellhead gas capture rate to 99.9%, achieving the
                company's near-term emission targets (in each case, based on
                preliminary estimates). The company also initiated deployment of
                a new continuous methane leak detection system called iSense
                <sup>®</sup>.
              </p>

              <p>
                <sup>*</sup> See reconciliation schedules.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2021"
            className=""
            iconStyle={{
              background: `${individualReaches["hr2021"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2021}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                2021 was a record-setting year for EOG. The company earned
                record net income of $4.7 billion and generated a record $5.5
                billion of free cash flow,<sup>1</sup> which funded a record
                cash return of $2.7 billion to shareholders.{" "}
              </p>
              <p>
                <strong>
                  EOG doubled the regular dividend rate<sup>2</sup> to $3.00 per
                  share and paid two special dividends, paying out about 30% of
                  cash from operations.<sup>1</sup> Return of cash in 2021
                  placed EOG among the leaders in the E&P industry and across
                  the broader market.{" "}
                </strong>
              </p>
              <p>
                Despite inflationary pressure, EOG lowered well costs 7%.
                Individual well performance increased, and the company's
                diversified marketing strategy drove peer-leading price
                realizations. Key ESG metrics also improved, including the
                company's methane emissions percentage, wellhead gas capture,
                water reuse, and employee safety.{" "}
              </p>
              <p>
                At the start of the year, EOG doubled the internal hurdle rate
                for capital allocation from a minimum of 30% to 60% direct
                after-tax rate of return<sup>1</sup> at $40 crude oil and $2.50
                natural gas. By year-end, the company replaced 175% of the
                double-premium wells drilled throughout the year, further
                improving the overall quality of EOG's inventory.{" "}
              </p>

              <p>
                <sup>1</sup> See reconciliation schedules.
              </p>
              <p>
                <sup>2</sup> Annualized dividend rate increased to $3.00 per
                share compared to $1.50 in 2020.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2020"
            className=""
            iconStyle={{
              background: `${individualReaches["hr2020"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2020}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                2020 tested EOG like it has never been tested. At the start of
                the year, the coronavirus pandemic compounded what started as an
                oil price war, crushing oil demand in a market that was already
                oversupplied, and drove oil prices to levels the company had not
                seen in more than 20 years.{" "}
              </p>

              <p>
                <strong>
                  EOG generated $1.6 billion of free cash flow* which both paid
                  the dividend and further shored up what was already an
                  industry-leading balance sheet, all while WTI oil prices
                  averaged less than $40 per barrel.{" "}
                </strong>
              </p>
              <p>
                For the third year in a row, EOG increased the dividend at least
                30%. The company also announced a new premium natural gas play
                in South Texas named Dorado. Dorado added 1,250 premium net
                drilling locations.{" "}
              </p>

              <p>
                EOG more than replaced the inventory drilled throughout the
                year. The company’s premium inventory had grown to 11,500 net
                locations by year end, which is more than three and a half times
                the total since introducing the premium well standard in 2016.{" "}
              </p>
              <p>* See reconciliation schedules.</p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2019"
            className=""
            iconStyle={{
              background: `${individualReaches["hr2019"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2019}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  For the third year in a row, EOG delivered on our goal of
                  double-digit returns and double-digit growth in a modest oil
                  price environment – we earned 12% return on capital employed*
                  and increased oil production 14% while WTI oil price averaged
                  $57 per barrel.
                </strong>
              </p>
              <p>
                For the second year in a row, we increased the dividend rate
                31%. We also generated $1.9 billion of free cash flow* that
                funded $588 million in dividends and the retirement of $900
                million of debt.
              </p>
              <p>
                EOG announced two new premium plays in the Delaware Basin, the
                Wolfcamp M and Third Bone Spring, adding 1,500 premium net
                drilling locations and estimated net resource potential of 1.6
                BnBoe.
              </p>
              <p>
                By year-end, EOG’s premium inventory had grown to 10,500
                locations, which is more than three times the total since
                introducing the premium well standard in 2016.
              </p>
              <p>* See reconciliation schedules.</p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2018"
            iconStyle={{
              background: `${individualReaches["hr2018"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2018}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  EOG delivered a premium combination in 2018: double digit
                  return and double digit growth.
                </strong>
              </p>
              <p>
                Return on capital employed was 15%* and we grew oil production
                19%.
              </p>
              <p>
                We earned record net income, generated record free cash flow*
                and increased the dividend rate 31%.
              </p>
              <p>
                EOG announced two new premium plays in the Powder River Basin,
                the Mowry and the Niobrara, adding more than 1,500 premium net
                drilling locations and estimated net resource potential of 1.9
                BnBoe.
              </p>
              <p>
                We published our inaugural Sustainability Report demonstrating
                our commitment to transparency on the ESG metrics that are most
                relevant to our business and most important to our shareholders
                and other stakeholders.
              </p>
              <p>* See reconciliation schedules.</p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2017"
            iconStyle={{
              background: `${individualReaches["hr2017"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2017}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  Our premium drilling program delivered 20% U.S. oil production
                  growth.
                </strong>
              </p>
              <p>
                We proved up 150,000 net acres across two new plays, the
                Delaware Basin First Bone Spring and Eastern Anadarko Basin
                Woodford Oil Window.
              </p>
              <p>
                We replaced almost four times the number of wells completed,
                adding 2,000 net locations to our growing portfolio of premium
                oil assets.
              </p>
              <p>
                By year end, EOG’s premium inventory totaled 8,000 net locations
                and 7.3 BnBoe of estimated net resource potential in geologic
                sweet spots across six areas, the Delaware Basin, Eagle Ford,
                Bakken, Powder River Basin, DJ Basin and Eastern Anadarko Basin.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2016"
            iconStyle={{
              background: `${individualReaches["hr2016"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2016}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  “Premium” is born. EOG establishes a new standard for capital
                  allocation: the premium well, delivering a minimum 30% direct
                  after-tax rate of return* at $40 crude oil and $2.50 natural
                  gas.
                </strong>
              </p>
              <p>
                We identified 6,000 premium net drilling locations. That’s more
                than 10 years of inventory at our 2016 drilling pace.
              </p>
              <p>
                EOG merges with a historic New Mexico oil and gas company, Yates
                Petroleum, which adds 260,000 net acres in the core areas of the
                Delaware Basin and Powder River Basin.
              </p>
              <p>
                We commercialized the first enhanced oil recovery process, or
                EOR, in shale.
              </p>
              <p></p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2015"
            iconStyle={{
              background: `${individualReaches["hr2015"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2015}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  We added 6x as many new potential well locations as wells
                  drilled during the year.
                </strong>
              </p>
              <p>
                We increased our estimated net resource potential by 1.6 BnBoe
                across the Delaware Basin and Bakken through technical
                innovations in precision targeting and completion design,
                organic exploration and tactical, bolt-on acquisitions.
              </p>
              <p>
                We cemented our position as the leading producer in the
                world-class Eagle Ford play, producing a cumulative 285 million
                barrels of oil.
              </p>
              <p>
                Cost control and improved efficiencies were a hallmark of 2015
                company operations with cash operating costs down 17%.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2014"
            iconStyle={{
              background: `${individualReaches["hr2014"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2014}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>We delivered 16% ROE* and 14% ROCE*.</strong>
              </p>
              <p>
                We delivered 31% crude oil production growth and 17% total
                production growth.
              </p>
              <p>
                We raised the estimated net resource potential of our premier
                South Texas Eagle Ford crude oil asset from 2.2 BnBoe to 3.2
                BnBoe.
              </p>
              <p>
                We unveiled four crude oil and combo plays in the Rocky Mountain
                region with total estimated net resource potential of 400
                million barrels of oil.
              </p>
              <p>
                The board of directors approved a two-for-one stock split in the
                form of a stock dividend.
              </p>
              <p>
                *See reconciliation schedules to press release, dated February
                18, 2015.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2013"
            iconStyle={{
              background: `${individualReaches["hr2013"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2013}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  We became the largest producer of oil in the lower 48 states,
                  reaching 300,000 barrels of oil per day of gross operated
                  production.
                </strong>
              </p>
              <p>We grew oil production by 40% and total production by 9%.</p>
              <p>Our total proved reserves increased 17% to 2.1 BnBoe.</p>
              <p>
                We announced the Wolfcamp play on the Texas side of the Delaware
                Basin, adding 800 MMBoe of estimated net resource potential.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2012"
            iconStyle={{
              background: `${individualReaches["hr2012"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2012}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  Year-over-year non-GAAP earnings per share* grew 50% and crude
                  oil production grew 39%, driven by strong performance from the
                  South Texas Eagle Ford and North Dakota Bakken.
                </strong>
              </p>
              <p>
                We remain the top crude oil producer in the Eagle Ford, ending
                the year with production averaging 106,000 barrels of oil
                equivalent per day.
              </p>
              <p>
                We opened a crude-by-rail unloading terminal in St. James,
                Louisiana, giving us expanded access to the Gulf Coast refining
                markets.
              </p>
              <p>
                Reached a milestone at our state-of-the-art sand processing
                plant in Chippewa Falls, Wisconsin, with our first shipment of
                sand in January. In July, we marked another milestone with the
                departure of our 500th crude oil unit train from our Stanley,
                North Dakota, rail terminal, which opened in December 2009.
              </p>
              <p>
                We increased the cash dividend on the common stock by 6.25% to
                $0.17 per share.
              </p>
              <p>
                *See reconciliation schedules to press release, dated February
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2011"
            iconStyle={{
              background: `${individualReaches["hr2011"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2011}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  We reported net income of $1.1 billion compared to $161
                  million for 2010.
                </strong>
              </p>
              <p></p>
              <p>
                In the U.S., we grew crude oil production more than 60% driven
                by strong performance from the South Texas Eagle Ford, the Fort
                Worth Barnett Shale Combo, the Leonard and Wolfcamp Shales in
                the Permian Basin, and the North Dakota Bakken.
              </p>
              <p>We were the top crude oil producer in the Eagle Ford.</p>
              <p>
                We increased the cash dividend on the common stock by 3% to
                $0.16 per share.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2010"
            iconStyle={{
              background: `${individualReaches["hr2010"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2010}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  We announce the South Texas Eagle Ford oil play, our more than
                  half a million-net acre position there, and for the first year
                  in EOG’s history, we generate more revenues from crude oil,
                  condensate and natural gas liquids production than from
                  natural gas production.
                </strong>
              </p>
              <p>
                In addition to our massive position in the Eagle Ford, EOG’s
                cadre of liquids-rich assets includes the North Dakota
                Bakken/Three Forks in the Williston Basin, the Fort Worth
                Barnett Shale Combo, the Leonard Shale in the Permian Basin, as
                well as the Denver-Julesberg (DJ) Basin Horizontal Niobrara.
              </p>
              <p>
                The first train to transport crude oil for EOG Resources arrives
                in Stroud, Oklahoma on January 3, 2010.
              </p>
              <p>
                We increased the cash dividend on the common stock by 7%, the
                11th increase in 11 years.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2009"
            iconStyle={{
              background: `${individualReaches["hr2009"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2009}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  We increased total company proved reserves by 2,087 Bcfe to
                  10.8 Tcfe – 24 percent higher than year–end 2008.
                </strong>
              </p>
              <p>
                Our total North American liquids production grew 30%, comprised
                of 23% growth in crude oil and condensate and 48% in natural gas
                liquids.
              </p>
              <p>
                The first train to transport crude oil for EOG Resources
                departed Stanley, North Dakota on December 31, 2009.
              </p>
              <p>
                We maintained a conservative balance sheet, ending the year with
                a net debt–to–total capitalization ratio* of 17%.
              </p>
              <p>
                We increased the cash dividend on the common stock to $0.145 per
                share, an increase of 7%.
              </p>
              <p>
                *See reconciliation schedules to press release, dated February
                9, 2010.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2008"
            iconStyle={{
              background: `${individualReaches["hr2008"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2008}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  We more than doubled net income available to common
                  stockholders, earning $2.4 billion compared to $1.1 billion in
                  2007.
                </strong>
              </p>
              <p>
                We organically grew overall production 15% and crude oil
                production 46%, driven primarily by ongoing drilling success in
                the North Dakota Bakken Play.
              </p>
              <p>Total company proved reserves increased 12% to 8.7 Tcfe.</p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2007"
            iconStyle={{
              background: `${individualReaches["hr2007"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2007}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  We organically grew our overall year-over-year production 11%
                  and United States natural gas production 19%.
                </strong>
              </p>
              <p>
                Crude oil and condensate production grew by 11% while natural
                gas liquids production increased 31%.
              </p>
              <p>
                Our proved reserves were approximately 7.7 Tcfe, a 14% increase.
                From drilling alone, we added 1,534 Bcfe of proved reserves.
              </p>
              <p>
                Recognizing the company’s strong financial position, Standard
                and Poor’s upgraded us to A–.
              </p>
              <p>We again increased the cash dividend on the common stock.</p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2006"
            iconStyle={{
              background: `${individualReaches["hr2006"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2006}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  Our overall organic year-over-year production increased 9% and
                  United States natural gas production grew 14% driven by our
                  activity in the Fort Worth Basin Barnett Shale, Northeastern
                  Utah Uinta Basin and South Texas Frio and Lobo Plays.
                </strong>
              </p>
              <p>
                Our proved reserves increased by 607 Bcfe, to 6.8 Tcfe, a 10%
                increase.
              </p>
              <p>
                Our results from the Fort Worth Basin Barnett Shale Play
                exceeded expectations with production at 206 MMcfd, exceeding
                the original year-end goal of 155 MMcfd.
              </p>
              <p>We reduced long-term debt outstanding to $733 million.</p>
              <p></p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2005"
            iconStyle={{
              background: `${individualReaches["hr2005"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2005}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  We reported our first full year of production from the UK
                  North Sea and commenced natural gas production to supply
                  feedstock for the M5000 Methanol Plant in Trinidad.
                </strong>
              </p>
              <p></p>
              <p>
                Our proved reserves at year-end were approximately 6.2 Tcfe, an
                increase of 548 Bcfe.
              </p>
              <p>
                We executed a two-for-one stock split and increased our annual
                common stock dividend 33%.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2004"
            iconStyle={{
              background: `${individualReaches["hr2004"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2004}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  We had approximately 400,000 acres under lease in the Texas
                  Barnett Shale Play with net natural gas production reaching 30
                  MMcfd during December.
                </strong>
              </p>
              <p>
                We commenced production from two Southern Gas Basin wells in the
                United Kingdom North Sea.
              </p>
              <p>
                We began natural gas sales to the Nitro 2000 (N2000) Ammonia
                Plant in Trinidad.
              </p>
              <p></p>
              <p>
                Our proved reserves were approximately 5.6 Tcfe, an increase of
                430 Bcfe.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2003"
            iconStyle={{
              background: `${individualReaches["hr2003"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2003}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  We closed the largest acquisition to-date in EOG’s history
                  with the purchase of primarily natural gas properties in
                  southeast Alberta, Canada, for U.S. $320 million.
                </strong>
              </p>
              <p>
                Our proved reserves were approximately 5.2 Tcfe, an increase of
                614 Bcfe.
              </p>
              <p>
                Our increased reserves replaced 249% of production for a low
                total company all-in finding cost of $1.28 per Mcfe.
              </p>
              <p>
                We significantly improved our financial position, reducing our
                debt-to-total capitalization ratio from 41% to 33% year-end
                2002.
              </p>
              <p></p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2002"
            iconStyle={{
              background: `${individualReaches["hr2002"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2002}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  In Trinidad, we announced the Parula natural gas discovery,
                  added two new offshore exploration blocks, successfully
                  started up the CNC Ammonia Plant and signed a 25-year
                  extension on the offshore SECC Block.
                </strong>
              </p>
              <p>
                Our proved reserves increased by 9% to approximately 4.6 Tcfe,
                replacing 193% of production at a finding cost of $1.06 per
                Mcfe.
              </p>
              <p>
                We increased total Canadian production 23% and natural gas
                production 22%, as compared to 2001.
              </p>
              <p>
                We reduced the number of EOG shares outstanding by repurchasing
                approximately 700,000 shares of common stock, net of option
                exercises, stock plans and other increases.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2001"
            iconStyle={{
              background: `${individualReaches["hr2001"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2001}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  We delivered a return on common shareholders’ equity of 28.4%
                  for the year.
                </strong>
              </p>
              <p>
                We replaced 201% of production from all sources at a finding
                cost of $1.36 per Mcfe.
              </p>
              <p></p>
              <p>
                Our debt-to-total-capitalization ratio, one of the lowest in the
                industry, improved to 34%.
              </p>
              <p>
                The common stock dividend increased by $0.02 to $0.16 per share.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="2000"
            iconStyle={{
              background: `${individualReaches["hr2000"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref2000}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  Our stock appreciated 211% and we were added to the Standard
                  &amp; Poor’s 500 Index after the close of trading on November
                  1, 2000.
                </strong>
              </p>
              <p>
                We were ranked as the third best performer in the Standard and
                Poor’s 500 Index.
              </p>
              <p>We were the second most active driller in the U.S.</p>
              <p>Our total production increased 8.9%.</p>
              <p>
                We signed a 15-year natural gas supply contract with the
                National Gas Company of Trinidad and Tobago Limited (NGC) to
                supply an ammonia plant.
              </p>
              <p>
                The annual common stock dividend increased from $0.12 per share
                to $0.14 per share.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="1999"
            iconStyle={{
              background: `${individualReaches["hr1999"] ? "red" : "black"}`,
            }}
            icon={
              <div className="icon-holder" ref={ref1999}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            }
          >
            <div className="timeline-content">
              <p>
                <strong>
                  EOG Resources, Inc. (EOG) was born, declaring our independence
                  from Enron Corp.
                </strong>
              </p>
            </div>
          </VerticalTimelineElement>
          <div
            className="line-growth"
            ref={refGrowBar}
            style={{ height: `${reachedHeight}px` }}
          ></div>
        </VerticalTimeline>
      </div>
    </Layout>
  )
}
